import Accordion from "../../../../content/posts/components/Accordion";
import Caption from "../../../../content/posts/components/Caption";
import GIF from "../../../../content/posts/components/GIF";
import ScrollToTopButton from "../../../../content/posts/components/ScrollToTopButton";
import * as React from 'react';
export default {
  Accordion,
  Caption,
  GIF,
  ScrollToTopButton,
  React
};